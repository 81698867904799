import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"


const InformedConsent = () => (
  <Layout sectionTitle={"Informed Consent"}>
    <SEO title="Informed Consent" />
    <Container className="text-justify">
      <h2>Informatie over Risico's, Beperkingen en Ongemakken bij een Orthodontische Behandeling</h2>
      <h3>Wat houdt een orthodontische behandeling in?</h3>
      <p>Een orthodontische behandeling is gericht op het verkrijgen van een mooi en functioneel gebit. Dit proces vereist niet alleen de deskundigheid van de orthodontist, maar ook een actieve medewerking van de patiënt. Het is belangrijk goed geïnformeerd te zijn over het verloop van de behandeling en de verwachtingen. Hoewel orthodontische behandelingen over het algemeen veilig zijn, kunnen ze ongemakken, risico’s en beperkingen met zich meebrengen. Deze factoren moeten worden meegewogen bij het besluit om al dan niet een behandeling te ondergaan.</p>
      <h3>Alternatieve mogelijkheden</h3>
      Een orthodontische behandeling is geen verplichting; er bestaan soms alternatieven, afhankelijk van de gebitssituatie:
      <ul>
        <li>Geen behandeling ondergaan en de huidige stand van de tanden accepteren.</li>
        <li>Het verwijderen van bepaalde tanden waardoor de positie van andere tanden spontaan enigszins ken verbeteren.</li>
        <li>Chirurgische correcties aan de kaken.</li>
        <li>Cosmetische aanpassingen, zoals tandkleurige vullingen of prothetische oplossingen om een afwijkende stand van de tanden te camoufleren </li>
        <li>Een compromisbehandeling waarbij slechts een deel van de afwijking behandeld wordt. </li>
      </ul>

      <h3>Gezondheidsfactoren en medicatie</h3>
      <p>Bepaalde medische aandoeningen en medicijnen kunnen invloed hebben op de orthodontische behandeling. Zo kunnen botziekten, osteoporose en bepaalde hart- of hormonale aandoeningen de behandeling beïnvloeden. Sommige medicijnen, zoals bisfosfonaten, kunnen het verplaatsen van tanden vertragen en ontstekingen in het kaakbot veroorzaken. Informeer altijd uw orthodontist over uw gezondheid en medicijngebruik.</p>
      <h3>Allergieën en leefstijl</h3>
      <ul>
        <li>Materialen in de beugel, zoals metalen of kunststof, kunnen allergische reacties veroorzaken. Dit kan ertoe leiden dat het behandelingsplan gewijzigd moet worden of dat de behandeling zelfs moet stoppen.</li>
        <li>Roken en tabaksgebruik verhogen het risico op mondkanker en vertraagde wondgenezing.</li>
      </ul>

      <h3>Röntgenfoto’s en diagnostiek</h3>
      <p>Voor de behandeling worden röntgenfoto’s gemaakt. De gebruikte straling is gering en niet schadelijk. Informeer uw orthodontist als u zwanger bent of recent röntgenfoto's heeft laten maken.</p>
      <h3>Behandelingsduur en succesfactoren</h3>
      <p>De duur van een orthodontische behandeling hangt af van factoren zoals de ernst van de afwijking en de medewerking van de patiënt. Soms moet de orthodontische behandeling worden bijgesteld als de groei niet volgens verwachting verloopt. Soms kan een kaakchirurgische correctie van de stand van de kaken noodzakelijk zijn om een optimaal resultaat te verkrijgen. Ook kunnen na de orthodontische behandeling nog groeiveranderingen optreden die mogelijk een herbehandeling noodzakelijk maken.
        Een vlot behandelingsverloop hangt sterk af van het naleven van de instructies, goede mondhygiëne en regelmatige afspraken.</p>
      <h3>Mogelijke ongemakken</h3>
      <ul>
        <li>Gevoelige tanden en kiezen na plaatsing of aanpassing van de beugel.</li>
        <li>Tijdelijke hinder bij praten of eten.</li>
        <li>Drukplekken op tandvlees of wangen gaan meestal na enkele dagen vanzelf weg.</li>
      </ul>

      <h3>Langdurige effecten en nazorg</h3>
      <p>Een orthodontische behandeling garandeert niet dat tanden en kiezen voor altijd perfect recht blijven staan. Daarom wordt na de correctie een retentiebeugel of spalkje geplaatst. <br />
        Factoren zoals mondademhaling, tongpersen, druk wijsheidstanden of groei van de kaken kunnen de tandstand alsnog beïnvloeden.</p>
      <h3>Risico’s en complicaties</h3>
      <ul>
        <li>Wortelresorptie (inkorting van tandwortels). Meestal in geringe mate. Indien meer dan gemiddeld kan de behandeling hierdoor vroegtijdig beëindigd worden. </li>
        <li>Afsterven van de tandzenuw door verplaatsing van reeds beschadigde tanden (val melkgebit).</li>
        <li>Onmogelijkheid om een tand te verplaatsen owv ankylose (vaste verbinding van tand aan het omliggend bot) waardoor extractie van dit element noodzakelijk wordt.</li>
        <li>Losraken of inslikken van onderdelen van de beugel.</li>
        <li>Kaakgewrichtsklachten, zoals klik of pijn bij openen/sluiten van de mond.</li>
        <li>Beschadiging van het glazuur of van bestaande restauraties bij verwijdering van de blokjes. </li>
      </ul>

      <h3>Aanvullende behandelingen</h3>
      <p>In sommige gevallen zijn extra tandheelkundige ingrepen nodig om het eindresultaat te optimaliseren, zoals  extra vullingen, kroon- en brugwerk of tandvleesbehandelingen. De kosten van deze behandelingen vallen buiten het tarief voor de orthodontische behandeling. </p>

      <h3>Speciale technieken: botankers</h3>
      <p>Botankers of microschroefjes kunnen noodzakelijk zijn om de tanden efficiënter te verplaatsen. Dit brengt extra risico’s met zich mee, zoals ontstekingsreacties, losraken van de schroef of beschadiging van tandwortels.</p>
    </Container>
  </Layout>
)

export default InformedConsent
